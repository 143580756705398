<template>
    <div class="map" id="mapContainer" oncontextmenu="return false;"/>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import ButtonStateMachine from "@/classes/ButtonStateMachine.js";
import Backend from "@/classes/Backend.js"

export default {
    name: 'Map',
    data() {
        return {
            map: {},
            buttonStateMachine: null,
            backend: null,
            markers: [],
            polygon: null,
            circle: null,
            trackRadius: false
        };
    },
    mounted() {
        let poi = {
            shkiperka: [59.9335, 30.226],
        };

        let mapServer = window.location.origin+'/api/tile';

        // let osm = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        let osm = L.tileLayer(mapServer+'?p=osm&s={s}&z={z}&x={x}&y={y}.png', {
            attribution: '&copy; Аэроскрипт | &copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
            subdomains: ['a', 'b', 'c'],
            maxZoom: 19,
            minZoom: 3
        });

        let mapbox_str = L.tileLayer(mapServer+'?p=mapbox&z={z}&x={x}&y={y}', {
            attribution: '&copy; <a href="https://apps.mapbox.com/feedback/">Mapbox</a> | &copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
            maxZoom: 19,
            minZoom: 3,
            tileSize: 512,
            zoomOffset: -1
        });

        let mapbox_sat = L.tileLayer(mapServer+'?p=mapbox_sat&z={z}&x={x}&y={y}', {
            attribution: '&copy; <a href="https://apps.mapbox.com/feedback/">Mapbox</a> | &copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
            maxZoom: 19,
            minZoom: 3,
            tileSize: 512,
            zoomOffset: -1
        });

        var tileMaps = {
            'Open Street Map': osm,
            'Mapbox Streets': mapbox_str,
            'Mapbox Satellite': mapbox_sat
        };

        this.map = L.map('mapContainer', {
            zoomControl: true,
            preferCanvas: true,
            renderer: L.canvas(),
            layers: [mapbox_str]
        });

        L.control.scale().addTo(this.map);
        L.control.layers(tileMaps, {}, {position: 'topleft'}).addTo(this.map);

        this.map.on('mousedown', event => this.onMouseDown(event));
        this.map.on('mouseup', event => this.onMouseUp(event));
        this.map.on('mousemove', event => this.onMouseMove(event));
        document.onkeydown = this.onDocumentKeyDown;

        this.map.setView(poi.shkiperka, 13);

        this.buttonStateMachine = new ButtonStateMachine(
            this.onEvent
        );

        this.backend = new Backend();
    },
    methods: {
        onMouseDown(event) {
            // console.log('mouse down detected', event.originalEvent);
            let latlng = this.map.mouseEventToLatLng(event.originalEvent);
            // console.log('latlng =', latlng);
            this.buttonStateMachine.onKeyDown(
                event.originalEvent.buttons, latlng.lat, latlng.lng);
        },
        onMouseUp(event) {
            // console.log('mouse up detected', event.originalEvent);
            let latlng = this.map.mouseEventToLatLng(event.originalEvent);
            this.buttonStateMachine.onKeyUp(
                event.originalEvent.buttons, latlng.lat, latlng.lng);
        },
        onMouseMove(event) {
            let latlng = this.map.mouseEventToLatLng(event.originalEvent);
            this.$emit('latLng', {lat:latlng.lat, lng:latlng.lng});
            if (this.trackRadius && this.circle != null) {
                this.circle.setRadius(this.map.distance(
                    this.circle.getLatLng(),
                    [latlng.lat, latlng.lng]));
            }
        },
        onEvent(event, data) {
            // console.log('event', event);
            if (event == 'start') {
                // console.log('start');
                this.clearMarkers();
                this.map.dragging.disable();
            } else if (event == 'point') {
                // console.log('add point at', data.lat, data.lng);
                this.markers.push(L.circleMarker([data.lat, data.lng]).addTo(this.map));
            } else if (event == 'track-radius') {
                // console.log('track radius');
                this.circle = L.circle(this.markers[0].getLatLng()).addTo(this.map);
                this.trackRadius = true;
            } else if (event == 'radius') {
                // console.log('radius at', data.lat, data.lng);
                this.circle.setRadius(this.map.distance(
                    this.circle.getLatLng(),
                    [data.lat, data.lng]));
                this.trackRadius = false;
                this.backend.getCircleHeights(
                    json => {
                        console.log('circle heights', json);
                        this.circle.bindTooltip(
                            `min=${json.min}<br>max=${json.max}<br>q=${json.quality}`,
                            {permanent: true, direction: 'center'}).openTooltip(); },
                    error => {},
                    this.circle.getLatLng().lat,
                    this.circle.getLatLng().lng,
                    this.circle.getRadius());
            } else if (event == 'finish') {
                // console.log('finish');
                if (this.circle != null) {
                    console.log('get circle heights');
                    this.map.fitBounds(this.circle.getBounds());
                } else if (this.markers.length > 1) {
                    let points = [];
                    this.markers.forEach(marker => points.push([marker.getLatLng().lat, marker.getLatLng().lng]));
                    this.polygon = L.polygon(points).addTo(this.map);
                    this.map.fitBounds(this.polygon.getBounds());
                    points = [];
                    this.markers.forEach(marker => points.push({lat: marker.getLatLng().lat, lng: marker.getLatLng().lng}));
                    this.backend.getPolygonHeights(
                        json => {
                            console.log('polygon heights', json);
                            this.polygon.bindTooltip(
                            `min=${json.min}<br>max=${json.max}<br>q=${json.quality}`,
                            {permanent: true, direction: 'center'}).openTooltip(); },
                            error => {},
                            points);
                } else if (this.markers.length > 0) {
                    this.backend.getHeight(
                        // json => console.log('backend reply =', json),
                        json => {
                            this.markers[0].bindTooltip(`h=${json.height}<br>q=${json.quality}`);
                            this.markers[0].openTooltip(); },
                        error => {
                            this.markers[0].bindTooltip('error');
                            this.markers[0].openTooltip(); },
                        this.markers[0].getLatLng().lat,
                        this.markers[0].getLatLng().lng);
                }

                this.map.dragging.enable();
            }
        },
        onDocumentKeyDown(event) {
            if (event.key == 'Escape') {
                // console.log('Esc key detected');
                this.clearMarkers();
            }
        },
        clearMarkers() {
            this.markers.forEach(marker => {
                marker.unbindTooltip();
                marker.remove();
            });
            this.markers = [];

            if (this.polygon != null) {
                this.polygon.unbindTooltip();
                this.polygon.remove();
            }
            this.polygon = null;

            if (this.circle != null) {
                this.circle.unbindTooltip();
                this.circle.remove();
            }
            this.circle = null;
        }
    }
}
</script>

<style scoped>
.map {
    width: 100%;
    height: 100%;
}
</style>