"use strict"
export default class ButtonStateMachine {
    constructor(callback) {
        this.callback = callback;

        this.state = 'init'; // block, ready, point, polygon, circle, finish
        this.leftBtn = false;
        this.rightBtn = false;

        this.executor = {
            'init': {
                left: (down, lat, lng) => this.initLeft(down, lat, lng),
                right: (down, lat, lng) => this.initRight(down, lat, lng)
            },
            'block': {
                left: (down, lat, lng) => this.blockLeft(down, lat, lng),
                right: (down, lat, lng) => this.blockRight(down, lat, lng)
            },
            'ready': {
                left: (down, lat, lng) => this.readyLeft(down, lat, lng),
                right: (down, lat, lng) => this.readyRight(down, lat, lng)
            },
            'point': {
                left: (down, lat, lng) => this.pointLeft(down, lat, lng),
                right: (down, lat, lng) => this.pointRight(down, lat, lng)
            },
            'polygon': {
                left: (down, lat, lng) => this.polygonLeft(down, lat, lng),
                right: (down, lat, lng) => this.polygonRight(down, lat, lng)
            },
            'circle': {
                left: (down, lat, lng) => this.circleLeft(down, lat, lng),
                right: (down, lat, lng) => this.circleRight(down, lat, lng)
            },
            'finish': {
                left: (down, lat, lng) => this.finishLeft(down, lat, lng),
                right: (down, lat, lng) => this.finishRight(down, lat, lng)
            }
        }
    }

    onKeyDown(buttons, lat, lng) {
        if ((buttons & 1) == 1 && !this.leftBtn) {
            this._leftBtnDown();
        }
        if ((buttons & 2) == 2 && !this.rightBtn) {
            this._rightBtnDown(lat, lng);
        }
    }
    onKeyUp(buttons, lat, lng) {
        if ((buttons & 1) == 0 && this.leftBtn) {
            this._leftBtnUp();
        }
        if ((buttons & 2) == 0 && this.rightBtn) {
            this._rightBtnUp(lat, lng);
        }
    }

    _setState(state) {
        this.state = state;
        // console.log('state =', state);
        if (state == 'circle') this.callback('track-radius');
    }

    _leftBtnDown() {
        // console.log('leftBtnDown');
        this.leftBtn = true;
        this.executor[this.state].left(true);
    }
    _leftBtnUp() {
        // console.log('leftBtnUp');
        this.leftBtn = false;
        this.executor[this.state].left(false);
    }
    _rightBtnDown(lat, lng) {
        // console.log('rightBtnDown');
        this.rightBtn = true;
        this.executor[this.state].right(true, lat, lng);
    }
    _rightBtnUp(lat, lng) {
        // console.log('rightBtnUp');
        this.rightBtn = false;
        this.executor[this.state].right(false, lat, lng);
    }
    initLeft(down, lat, lng) {
        if (down) this._setState('ready');
    }
    initRight(down, lat, lng) {
        if (down) this._setState('block');
    }
    blockLeft(down, lat, lng) {
        if (!down && !this.rightBtn) this._setState('init');
    }
    blockRight(down, lat, lng) {
        if (!down && !this.leftBtn) this._setState('init');
    }
    readyLeft(down, lat, lng) {
        if (!down) this._setState('init');
    }
    readyRight(down, lat, lng) {
        if (down) {
            this.callback('start');
            this.callback('point', {lat, lng});
            this._setState('point');
        }
    }
    pointLeft(down, lat, lng) {
        if (!down) this._setState('circle');
    }
    pointRight(down, lat, lng) {
        if (!down) this._setState('polygon');
    }
    polygonLeft(down, lat, lng) {
        if (!down) {
            this.callback('finish');
            if (this.rightBtn) this._setState('finish');
            else if (!this.rightBtn) this._setState('init');
        }
    }
    polygonRight(down, lat, lng) {
        if (down) this.callback('point', {lat, lng});
    }
    circleLeft(down, lat, lng) {

    }
    circleRight(down, lat, lng) {
        if (!down) {
            this.callback('radius', {lat, lng});
            this.callback('finish');
            if (this.leftBtn) this._setState('finish');
            else this._setState('init');
        }
    }
    finishLeft(down, lat, lng) {
        if (!down && !this.rightBtn) this._setState('init');
    }
    finishRight(down, lat, lng) {
        if (!down && !this.leftBtn) this._setState('init');
    }
}
