<template>
  <div id="app">
    <Location :lat="lat" :lng="lng"/>
    <Map @latLng="onLatLng"/>
  </div>
</template>

<script>
import Map from '@/components/Map.vue'
import Location from '@/components/Location.vue'

export default {
  name: 'App',
  components: {
    Map,
    Location
  },
  data() {
    return {
      lat: 1.1,
      lng: 2.2
    }
  },
  methods: {
    onLatLng(latLng) {
      // console.log('latLng =', latLng);
      this.lat = latLng.lat;
      this.lng = latLng.lng;
    }
  }
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0px;
}

#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>
