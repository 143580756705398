<template>
  <div class="location">{{ lat }}<br>{{ lng }}</div>
</template>

<script>
export default {
    props: {
        lat: Number,
        lng: Number
    }
};
</script>

<style scoped>
.location {
    width: 200px;
    /* height: 60px; */
    position: fixed;
    left: 50px;
    bottom: 50px;
    background-color: #eeeeee;
    z-index: 1000;
}
</style>