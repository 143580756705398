"use strict"
export default class Backend {
    constructor() {

    }

    async getHeight(resolve, reject, lat, lng) {
        // console.log('resolve', resolve, 'reject', reject, 'lat', lat, 'lng', lng);
        try {
            let response = await fetch(`/api/point?lat=${lat}&lng=${lng}`);
            if (response.ok) {
                let json = await response.json();
                resolve(json);
            } else {
                throw new Error(`response=${response}`);
            }
        } catch (error) {
            reject(error);
        }
    }

    async getCircleHeights(resolve, reject, lat, lng, radius) {
        let options = {
            mode: 'cors',
            method: 'POST',
            headers: { 'Content-Type' : 'application/json' },
            body: JSON.stringify({
                type: 'circle',
                center: { lat, lng },
                radius
            })
        };

        try {
            let response = await fetch(`/api/minmax`, options);
            if (response.ok) {
                let json = await response.json();
                resolve(json);
            } else {
                throw new Error(`response=${response}`);
            }
        } catch (error) {
            reject(error);
        }
    }

    async getPolygonHeights(resolve, reject, points) {
        let options = {
            mode: 'cors',
            method: 'POST',
            headers: { 'Content-Type' : 'application/json' },
            body: JSON.stringify({
                type: 'polygon',
                points
            })
        };

        try {
            let response = await fetch(`/api/minmax`, options);
            if (response.ok) {
                let json = await response.json();
                resolve(json);
            } else {
                throw new Error(`response=${response}`);
            }
        } catch (error) {
            reject(error);
        }
    }
}
